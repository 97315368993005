exports.words = {
    "02/03/2022": "Renna", 
    "02/04/2022": "Femur", 
    "02/05/2022": "Joint",
    "02/06/2022": "Ilium",
    "02/07/2022": "Notch",
    "02/08/2022": "Fossa",
    "02/09/2022": "Ramus",
    "02/10/2022": "Brain",
    "02/11/2022": "Ulnar",
    "02/12/2022": "Nerve",
    "02/13/2022": "Penis",
    "02/14/2022": "Heart",
    "02/15/2022": "Tibia",
    "02/16/2022": "Glute",
    "02/17/2022": "Booty",
    "02/18/2022": "Thigh",
    "02/19/2022": "Spine",
    "02/20/2022": "Facet",
    "02/21/2022": "Thorax",
    "02/22/2022": "Skull",
    "02/23/2022": "Boobs",
    "02/24/2022": "Sacrum",
    "02/25/2022": "Sural",
    "02/26/2022": "Thumb",
    "02/27/2022": "Pelvis",
    "02/28/2022": "Coccyx",
    "03/01/2022": "Fibula",
    "03/02/2022": "Talus",
    "03/03/2022": "Medial",
    "03/04/2022": "Fascia",
    "03/05/2022": "Delts",
    "03/06/2022": "Bicep",
    "03/07/2022": "Vagina",
    "03/29/2022": "Wrist",
    "03/31/2022": "Soleus",
    "04/01/2022": "Optic",
    "04/02/2022": "Pubis",
    "04/03/2022": "Scalp",
    "04/04/2022": "Talsus",
    "04/05/2022": "Digit",
    "04/06/2022": "Tract",
    "04/07/2022": "Radius",
    "04/08/2022": "Labia",
    "04/09/2022": "Sinus",
    "04/10/2022": "Lungs",
    "04/11/2022": "Dicks",
    "04/12/2022": "Lunate",
    "04/13/2022": "Magnus",
    "04/14/2022": "Ductus",
    "04/15/2022": "Vagus",
    "04/16/2022": "Crest",
    "04/17/2022": "Sella",
    "04/18/2022": "Teeth",
    "04/19/2022": "Mouth",
    "04/20/2022": "Ilium",
    "04/21/2022": "Minor",
    "04/22/2022": "Facial",
    "04/23/2022": "Teres",
    "04/24/2022": "Finger",
    "04/25/2022": "Weiner",
    "04/26/2022": "Bronchi",
    "04/27/2022": "Nails",
    "04/28/2022": "Joint",
    "04/29/2022": "Major",
    "04/30/2022": "Lymph",
    "04/31/2022": "Rectus",
    "05/01/2022": "Traps",
    "05/02/2022": "Ovale",
    "05/03/2022": "Labrum",
    "05/05/2022": "Flexor",
    "05/06/2022": "Atrium",
    "05/07/2022": "Stretch",
    "05/08/2022": "Muscle",
    "05/09/2022": "Triceps",
};